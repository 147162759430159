/**
 * @flow
 */

import React from 'react';
import FirebaseContainer from '../../../../common/firebase/FirebaseContainer';
import {
	buildMessageArchive, buildMessageArchives,
	buildMessageDrafts,
	buildMessages
} from '../../../../common/config/firebase_config/endpoints';
import type {
	TMessageApiData,
	TMessageResponse,
} from '../../../../common/types/api/TMessage';
import MessageItem from './MessageItem';

import Spinner from '../../../components/Spinner'

const compareDates = (dateA = {}, dateB = {}) => {
	const yearA = dateA.year ?? 0;
	const yearB = dateB.year ?? 0;
	const monthA = dateA.month ?? 0;
	const monthB = dateB.month ?? 0;
	const dayA = dateA.day ?? 0;
	const dayB = dateB.day ?? 0;
	const hoursA = dateA.hours ?? 0;
	const hoursB = dateB.hours ?? 0;
	const minutesA = dateA.minutes ?? 0;
	const minutesB = dateB.minutes ?? 0;
	const secondsA = dateA.seconds ?? 0;
	const secondsB = dateB.seconds ?? 0;

	if (yearA !== yearB) {
		return yearB - yearA;
	}
	if (monthA !== monthB) {
		return monthB - monthA;
	}
	if (dayA !== dayB) {
		return dayB - dayA;
	}
	if (hoursA !== hoursB) {
		return hoursB - hoursA;
	}
	if (minutesA !== minutesB) {
		return minutesB - minutesA;
	}
	return secondsB - secondsA;
}

type
TProps = {
	accountId: number;
	messages: Array < TMessageApiData >;
	onMessagesLoadSuccess: (data: TMessageResponse) => void;
	onMessageDraftsLoadSuccess: (data: TMessageResponse) => void;
	onMessageArchivesLoadSuccess: (data: TMessageResponse) => void;
	isDraft: boolean;
};

class MessageList extends React.Component<void, TProps, void> {
	_getQueries = () => ([{
		endpoint: this.props.isArchive ?
			buildMessageArchives(this.props.accountId) :
			this.props.isDraft ?
				buildMessageDrafts(this.props.accountId) :
				buildMessages(this.props.accountId),
		method: 'on',
		eventType: 'value',
		callback: this.props.isArchive ?
			this.props.onMessageArchivesLoadSuccess :
			this.props.isDraft ?
				this.props.onMessageDraftsLoadSuccess :
				this.props.onMessagesLoadSuccess,
		metadata: this.props.accountId,
	}]);

	_getItems = () => {
		return this.props.messages
			.sort((a, b) => compareDates(a.date, b.date))
			.map((message, i) => {
				return <MessageItem
					key={i}
					isDraft={this.props.isDraft}
					isArchive={this.props.isArchive}
					message={message}
					accountId={this.props.accountId}
					archiveMessage={this.props.archiveMessage}
					restoreArchivedMessage={this.props.restoreArchivedMessage}
					publishMessageDraft={this.props.publishMessageDraft}
					history={this.props.history}
				/>;
			});
	};

	render() {
		return (
			<FirebaseContainer queries={this._getQueries()}>
				<div>
					{this.props.messages ?
						this._getItems() :
						<Spinner />
					}
				</div>
			</FirebaseContainer>
		);
	}
}

export default MessageList;
