/**
 * @flow
 */

import React from 'react';
import {
  Flex,
  Box,
} from 'reflexbox';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../../theme/index';
import type { TBenefitApiData } from '../../../../common/types/api/TBenefit';
import { imageMain, imageUrl } from '../../../../common/config/images/endpoints';

const BenefitsCard = styled.div`
  border-radius: 40px;
  overflow: hidden;
  background-color: ${theme.userProfile.cardBackground};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 1em;

  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(0.99);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const LinkToDetails = styled(RouterLink)`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  padding-right: 1.5em;
`;

const BenefitInfoContainer = styled.div`
  padding: 0 2.5em 0 3.5em;
`;

const BenefitTitle = styled.h2`
  font-size: 1.5em;
  color: ${theme.categories.categoryColor};
`;

const BenefitText = styled.p`
  font-size: 1em;
  color: ${theme.categories.categoryColor};
  padding-bottom: 1.5em;
`;

const SourceText = styled.div`
  font-size: 0.9em;
  font-style: italic;
  padding-right: 1.0em;
  color: ${theme.categories.categoryColor};
`;

const BenefitImage = styled.img`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  border-radius: 50%;
  padding: 20px;
  display: block;
`;

const BenefitViewWrapper = styled.div`
    color: black;
`;

type TProps = {
  benefit: TBenefitApiData;
  accountId: number;
};

const BenefitItem = (props: TProps) => {
  const { benefit, accountId, isArchive, accounts, isDraft, benefitViews } = props;

  return (
    <LinkToDetails to={isArchive ? `/admin/benefits-archive/${benefit.benefitId}` : isDraft ? `/admin/benefits-draft/${benefit.benefitId}` : `/admin/benefits/${benefit.benefitId}`}>
      <BenefitsCard>
        <Flex wrap>
          <Box w={[1, 2 / 12]}>
            <BenefitImage src={imageMain(benefit.sourceAccount || accountId, benefit.benefitId)} alt="" />
          </Box>
          <Box w={[1, 9 / 12]}>
            <BenefitInfoContainer>
              <BenefitTitle> {benefit.heading} {props.disabled && <span style={{ color: 'red' }}>(Benefit is hidden)</span>}</BenefitTitle>
              <BenefitText>
                {benefit.description}
              </BenefitText>

              <Flex style={{
                flexDirection: "column",
                gap: "10px",
              }}>
                <BenefitViewWrapper>
                  No. views: <strong>{benefitViews || 0}</strong>
                </BenefitViewWrapper>
                {accounts && benefit.sourceAccount && benefit.sourceAccount !== accountId &&
                  <Flex>
                    <SourceText>Source: {accounts[benefit.sourceAccount].customerName}</SourceText>
                  </Flex>
                }
              </Flex>
            </BenefitInfoContainer>
          </Box>
        </Flex>
      </BenefitsCard>
    </LinkToDetails>
  );
};

export default BenefitItem;
