/**
 * Created by carlchristensen on 19.04.2017.
 */


//https://res.cloudinary.com/coveredpro/image/upload/v1492584999/benefit-images/111222333/-Ki1iyDteI5l2lvx0Sk6/main.jpg
//http://res.cloudinary.com/coveredpro/image/upload/c_scale,w_204/v1492584999/benefit-images/111222333/-Ki1iyDteI5l2lvx0Sk6/main.jpg


let bucket = process.env.REACT_APP_ENV === 'development' ? "coveredpro-dev" : "coveredpro";
let mediaToken = process.env.REACT_APP_ENV === 'development' ? "b017dd9f-8d1d-4f2e-85d4-29437e0e73f0" : "5c453475-fefa-454e-a588-e306499bd83e";

export const imageUrl = (accountId: string, benefitId:string, size:number) => `http://res.cloudinary.com/coveredpro/image/upload/c_scale,w_${size}/benefit-images/${accountId}/${benefitId}/main.jpg`;

export const imageThumb = (accountId: string, benefitId:string)  => imageThumbTemplate(accountId, benefitId, 'thumb');
export const imageMedium = (accountId: string, benefitId:string)  => imageThumbTemplate(accountId, benefitId, 'medium');
export const imageMain = (accountId: string, benefitId:string)  => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/benefit-images%2F${accountId}%2F${benefitId}%2Fmain.png?alt=media`;

export const imageThumbTemplate = (accountId: string, benefitId:string, type:string)  => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/benefit-images%2F${accountId}%2F${benefitId}%2Fthumb_${type}_main.jpg?alt=media`;
export const imageThumbUrlTemplate = (accountId: string, benefitId:string, type:string, name:string)  => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/benefit-images%2F${accountId}%2F${benefitId}%2Fthumb_${type}_${name}?alt=media`;


export const imageAccountTemplates = (type:string)  => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/account-images%2Ftemplate%2F${type}%2Fmain.png?alt=media&token=${mediaToken}`;
export const imageAccountSplashTemplate = ()  => imageAccountTemplates("splash")
export const imageAccountFeatureGraphicTemplate = ()  => imageAccountTemplates("featureGraphic")

export const buildCategoryImageEndpoint = (image, account)  => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/account-categories%2F${account}%2F${image}?alt=media&token=${mediaToken}`;

export const imageAccountLogo = (accountId: string) => `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/account-images%2F${accountId}%2Flogo%2Fmain.png?alt=media&token=${mediaToken}`;
