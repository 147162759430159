import { firebaseDb } from '../../config/firebase_config/firebase';
import {
  buildNewBenefit,
  buildBenefitWithCategory,
  buildBenefit,
  buildBenefitMaster,
  buildBenefitLocations,
  buildBenefitArchive,
  buildBenefitCopyMessage, buildBenefitDraft, buildHiddenVendorBenefit
} from '../../config/firebase_config/endpoints';

import history from '../../utils/history'
import { NotificationManager } from 'react-notifications';
import Geocode from 'react-geocode';

import { buildBenefitOrder } from '../../config/firebase_config/endpoints';


function updateBenefitRequest() {
  return {
    type: 'UPDATE_BENEFIT_REQUEST',
  };
}

function updateBenefitSuccess(key, data) {
  let action = {
    type: 'UPDATE_BENEFIT_SUCCESS',
  }

  if (key && data)
    action.payload = {
      key, data
    }
  return action
}


function updateBenefitMoveSuccess() {
  return {
    type: 'UPDATE_BENEFIT_MOVE_SUCCESS',
  }

}

function updateBenefitError(json) {
  return {
    type: 'UPDATE_BENEFIT_ERROR',
  };
}

const onVendorVisibilityChangeRequest = () => ({ type: 'UPDATE_VENDOR_VISIBILITY_REQUEST' });
const onVendorVisibilityChangeSuccess = (payload) => ({ type: 'UPDATE_VENDOR_VISIBILITY_SUCCESS', payload });
const onVendorVisibilityChangeFailure = (payload) => ({ type: 'UPDATE_VENDOR_VISIBILITY_FAILURE', payload });

const onBenefitOrderChangeRequest = () => ({ type: 'UPDATE_BENEFIT_ORDER_REQUEST' });
const onBenefitOrderChangeSuccess = (payload) => ({ type: 'UPDATE_BENEFIT_ORDER_SUCCESS', payload });
const onBenefitOrderChangeFailure = (payload) => ({ type: 'UPDATE_BENEFIT_ORDER_FAILURE', payload });

export const changeVendorBenefitVisibility = (benefitId, status) => async (dispatch, getState) => {

  try {

    dispatch(onVendorVisibilityChangeRequest());

    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let updates = {}

    updates[buildHiddenVendorBenefit(accountId, benefitId)] = status;

    await firebaseDb.ref().update(updates);

    dispatch(onVendorVisibilityChangeSuccess({ key: benefitId, status: status }));

  } catch (e) {
    console.log("Error in Change Vendor Visibility", e);
    dispatch(onVendorVisibilityChangeFailure(e))
  }
}

export const changeBenefitOrder = (benefitId, order) => async (dispatch, getState) => {
  try {
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let updates = {};

    updates[buildBenefitOrder(accountId, benefitId)] = order;

    await firebaseDb.ref().update(updates);

    dispatch(onBenefitOrderChangeSuccess({
      key: benefitId,
      order,
    }))
  } catch (e) {
    console.log("Error changing order of benefit");
    dispatch(onBenefitOrderChangeFailure(e));
  }
}

export const updateBenefit = (formValues, benefit, publish = false) => async (dispatch, getState) => {
  try {
    dispatch(updateBenefitRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let categoryId = "1";
    if (formValues.category.value || formValues.category.value === "0") {
      console.log(formValues.category.value);
      categoryId = formValues.category.value
    }
    else if (benefit && benefit.category) {
      console.log(benefit.category);
      categoryId = benefit.category
    }

    let updates = {};

    let benefitKey = ''

    if (benefit) {
      benefitKey = benefit.benefitId;
      const oldCategory = benefit.category;
      updates[buildBenefitWithCategory(accountId, benefitKey, oldCategory)] = null;
    }
    else {
      const newBenefitEndpoint = buildNewBenefit(accountId);
      benefitKey = firebaseDb.ref().child(newBenefitEndpoint).push().key;
    }

    let storableData = JSON.parse(JSON.stringify(formValues));
    storableData.category = categoryId;
    console.log(categoryId);

    updates[buildBenefitMaster(accountId, benefitKey)] = storableData;

    updates[buildBenefit(accountId, benefitKey)] = storableData;
    updates[buildBenefitWithCategory(accountId, benefitKey, categoryId)] = storableData;

    updates[buildBenefitLocations(accountId, benefitKey)] = storableData.locations || null;
    updates[buildBenefitDraft(accountId, benefitKey)] = null;


    console.log("update benefit called")
    await firebaseDb.ref().update(updates);

    dispatch(updateBenefitSuccess(benefitKey, storableData));
    if (!benefit) {
      history.replace('/admin/benefits/' + benefitKey)
      if (publish) NotificationManager.success('Success!', 'Benefit published!', 3000);
      else NotificationManager.success('Success!', 'Benefit saved!', 3000);
    }
    else {
      if (publish) history.replace('/admin/benefits/' + benefitKey);
      if (publish) NotificationManager.success('Success!', 'Benefit published!', 3000);
      else NotificationManager.success('Success!', 'Benefit saved!', 3000);
    }
  } catch (err) {
    console.log("update benefit ERROR: " + JSON.stringify(err))
    NotificationManager.error('Error!', JSON.stringify(err), 3000);

    dispatch(updateBenefitError());
  }
};

export const updateDraftBenefit = (formValues, benefit, disable = false, newBenefit = false) => async (dispatch, getState) => {
  try {
    dispatch(updateBenefitRequest());
    const store = getState();
    const accountId = store.auth.profile.data.accountId;

    let categoryId = "1";
    if (formValues && formValues.category && formValues.category.value) {
      categoryId = formValues.category.value
    }
    else if (benefit && benefit.category) {
      categoryId = benefit.category
    }

    let updates = {};

    let benefitKey = ''

    if (benefit) {
      benefitKey = benefit.benefitId;
      const oldCategory = benefit.category;
      updates[buildBenefitWithCategory(accountId, benefitKey, oldCategory)] = null;
    }
    else {
      const newBenefitEndpoint = buildNewBenefit(accountId);
      benefitKey = firebaseDb.ref().child(newBenefitEndpoint).push().key;
    }

    let storableData = JSON.parse(JSON.stringify(formValues));
    storableData.category = categoryId;


    updates[buildBenefitDraft(accountId, benefitKey)] = storableData;

    updates[buildBenefitMaster(accountId, benefitKey)] = null;

    updates[buildBenefit(accountId, benefitKey)] = null;
    updates[buildBenefitWithCategory(accountId, benefitKey, categoryId)] = null;

    updates[buildBenefitLocations(accountId, benefitKey)] = null;

    console.log("update benefitDraft called")
    await firebaseDb.ref().update(updates);

    dispatch(updateBenefitSuccess(benefitKey, storableData));
    if (newBenefit) {
      history.replace('/admin/benefits/' + benefitKey + "/edit-draft")
    }
    else if (!benefit) {
      history.replace('/admin/benefits-draft/' + benefitKey)
      if (disable) NotificationManager.success('Success!', 'Benefit disabled!', 3000);
      else NotificationManager.success('Success!', 'Benefit draft saved!', 3000);
    }
    else {
      if (disable) history.replace('/admin/benefits-draft/' + benefitKey);
      if (disable) NotificationManager.success('Success!', 'Benefit disabled!', 3000);
      else NotificationManager.success('Success!', 'Benefit draft saved!', 3000);
    }

  } catch (err) {
    console.log("update benefit draft ERROR: " + JSON.stringify(err))
    NotificationManager.error('Error!', JSON.stringify(err), 3000);

    dispatch(updateBenefitError());
  }
};


export const copyBenefit = (sourceAccountId, targetAccountId, sourceBenefit) => async (dispatch) => {
  try {
    dispatch(updateBenefitRequest());

    let updates = {};

    //const newBenefitEndpoint = buildBenefit(targetAccountId, sourceBenefit.benefitId);
    //const targetBenefitKey = firebaseDb.ref().child(newBenefitEndpoint).push().key;
    const targetBenefitKey = sourceBenefit.benefitId
    const storableData = sourceBenefit



    let categoryId = "1";
    if (storableData && storableData.category) {
      categoryId = storableData.category
    }


    updates[buildBenefitDraft(targetAccountId, targetBenefitKey)] = storableData;
    /*
        updates[buildBenefitMaster(targetAccountId, targetBenefitKey)] = storableData;
    
        updates[buildBenefit(targetAccountId, targetBenefitKey)] = storableData;
        updates[buildBenefitWithCategory(targetAccountId, targetBenefitKey, categoryId)] = storableData;
    
        updates[buildBenefitLocations(targetAccountId, targetBenefitKey)] = storableData.locations || null;
      */
    updates[buildBenefitCopyMessage(sourceAccountId, targetAccountId, targetBenefitKey)] = new Date().toJSON()

    console.log("update benefit called")
    await firebaseDb.ref().update(updates);

    dispatch(updateBenefitMoveSuccess());
    NotificationManager.success('Success!', 'Benefit copied!', 3000);

  } catch (err) {
    console.log("update benefit ERROR: " + JSON.stringify(err))
    NotificationManager.error('Error!', JSON.stringify(err), 3000);

    dispatch(updateBenefitError());
  }
};


export const archiveBenefit = (accountId, benefit) => async (dispatch, getState) => {
  try {
    dispatch(updateBenefitRequest());
    const store = getState();
    //const accountId = store.auth.profile.data.accountId;
    const categoryId = benefit.category;
    let benefitKey = benefit.benefitId;


    let updates = {};

    updates[buildBenefitArchive(accountId, benefitKey)] = benefit;

    updates[buildBenefitWithCategory(accountId, benefitKey, benefit.category)] = null;
    updates[buildBenefitMaster(accountId, benefitKey)] = null;
    updates[buildBenefit(accountId, benefitKey)] = null;
    updates[buildBenefitWithCategory(accountId, benefitKey, categoryId)] = null;
    updates[buildBenefitLocations(accountId, benefitKey)] = null
    updates[buildBenefitDraft(accountId, benefitKey)] = null

    console.log("UPDATING ARCHIVE: " + JSON.stringify(updates))

    await firebaseDb.ref().update(updates);

    dispatch(updateBenefitMoveSuccess());
    NotificationManager.success('Success!', 'Benefit archived!', 3000);
    history.replace('/admin/benefits');

  } catch (err) {
    NotificationManager.error('Error!', JSON.stringify(err), 3000);
    dispatch(updateBenefitError(err));
  }
};

export const unArchiveBenefit = (accountId, benefit) => async (dispatch, getState) => {
  try {
    dispatch(updateBenefitRequest());
    const store = getState();
    //const accountId = store.auth.profile.data.accountId;
    const categoryId = benefit.category;
    let benefitKey = benefit.benefitId;


    let updates = {};

    updates[buildBenefitDraft(accountId, benefitKey)] = benefit;
    /*
        updates[buildBenefitWithCategory(accountId, benefitKey, benefit.category)] = benefit;
        updates[buildBenefitMaster(accountId, benefitKey)] = benefit;
        updates[buildBenefit(accountId, benefitKey)] = benefit;
        updates[buildBenefitWithCategory(accountId, benefitKey, categoryId)] = benefit;*/
    /*
    if (benefit.locations) {
      updates[buildBenefitLocations(accountId, benefitKey)] = benefit.locations
    }*/

    updates[buildBenefitArchive(accountId, benefitKey)] = null;

    console.log("UPDATING ARCHIVE: " + JSON.stringify(updates))

    await firebaseDb.ref().update(updates);

    dispatch(updateBenefitMoveSuccess());
    NotificationManager.success('Success!', 'Benefit unarchived!', 3000);
    history.replace('/admin/benefits-archive');

  } catch (err) {
    NotificationManager.error('Error!', JSON.stringify(err), 3000);
    dispatch(updateBenefitError(err));
  }
};


function copyFbRecord(oldRef, newRef) {
  return Promise((resolve, reject) => {
    oldRef.once('value').then(snap => {
      return newRef.set(snap.val());
    }).then(() => {
      console.log('Done!');
      resolve();
    }).catch(err => {
      console.log(err.message);
      reject();
    });
  });
}

function moveFbRecord(oldRef, newRef) {
  return Promise((resolve, reject) => {
    oldRef.once('value').then(snap => {
      return newRef.set(snap.val());
    }).then(() => {
      return oldRef.set(null);
    }).then(() => {
      console.log('Done!');
      resolve();
    }).catch(err => {
      console.log(err.message);
      reject();
    });
  })
}
