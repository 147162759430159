/**
 * @flow
 */
import {getFormValues} from 'redux-form';
import {connect} from 'react-redux';

import MessageEdit from './MessageEdit';
import type {TAppState} from '../../../../common/TAppState';
import {
	onMessagesLoadSuccess,
	updateMessage,
	onTokensLoadSuccess,
	updateMessageDraft, onMessageArchivesLoadSuccess
} from '../../../../common/messages/actions';
import {onAccountsLoadSuccess} from "../../../../common/accounts/actions";
import {onBenefitsLoadSuccess} from "../../../../common/benefits/actions";

const MessageEditContainer = connect(
	(state: TAppState, dispatch) => {
		console.log('state', state)
		return {
			accountId: state.auth.profile.data.accountId,
			messages: state.messages.data || [],
			tokens: state.messages.tokens || {},
			messageFormValues: getFormValues('MessageForm')(state),
			accounts: state.accounts.list,
			benefits: state.benefits,
			isDraft: false,
			isArchive: false,
		};
	},
	(dispatch) => ({
		onMessagesLoadSuccess: (data) => dispatch(onMessagesLoadSuccess(data)),
		onMessageArchivesLoadSuccess: (data) => dispatch(onMessageArchivesLoadSuccess(data)),
		onBenefitsLoadSuccess: (data, metadata, account) => dispatch(onBenefitsLoadSuccess(data, metadata, account)),
		onTokensLoadSuccess: (data) => dispatch(onTokensLoadSuccess(data)),
		updateMessage: (data, message, accounts) => dispatch(updateMessage(data, message, accounts)),
		updateMessageDraft: (data, message) => dispatch(updateMessageDraft(data, message)),
		onAccountsLoadSuccess: (data) => dispatch(onAccountsLoadSuccess(data)),
	})
)(MessageEdit);

export default MessageEditContainer;
