/**
 * @flow
 */

import React, {useState} from 'react';
import {
	Flex,
	Box,
} from 'reflexbox';
import styled from 'styled-components';
import theme from '../../../theme/index';
import type {TMessageApiData} from '../../../../common/types/api/TMessage';
import {confirmAlert} from "react-confirm-alert";
import BaseButton from "../../../components/Typografy/BaseButton";
import {MultiSelect} from "react-multi-select-component";


const MessagesCard = styled.div`
  border-radius: 20px;
  margin-top: 2em;
  color: ${theme.benefits.textColor};
`;


const MessageInfoContainer = styled.div`
  padding: 0 2.5em 1.5em 2.5em;
`;


type
TProps = {
	message: TMessageApiData;
	onArchive: Function;
	accountId: number;
};


const deleteAction = (props: TProps) => {
	confirmAlert({
		title: 'Confirm to archive',
		message: 'Are you sure you want to archive this message?',
		buttons: [{
			label: 'Archive',
			onClick: () => props.archiveMessage(props.message)
		},
			{
				label: 'Cancel'
			}
		]
	})
};

const draftAction = (props: TProps) => {
	confirmAlert({
		title: 'Restore to draft',
		message: 'Are you sure you want to move this message back to draft?',
		buttons: [{
			label: 'Restore',
			onClick: () => props.restoreArchivedMessage(props.message)
		},
			{
				label: 'Cancel'
			}
		]
	})
};

const publishAction = (props: TProps) => {
	confirmAlert({
		title: 'Publish message',
		message: props.notification ?
			'Are you sure you want to publish this draft? This will send push notifications to users.'
				:
			'Are you sure you want to publish this draft? This will not send push notifications to users.',
		buttons: [{
			label: 'Publish',
			onClick: () => props.publishMessageDraft(props.message, props.accounts, props.notification)
		},
			{
				label: 'Cancel',
			}
		],
	})
}

const DeleteButtonContainer = styled.div`
  width: 100%;
  display: flex;
	flex-direction: row;
  justify-content: flex-end;
	align-items: center;
	align-content: center;
	padding: 2em 0;
`;

const LastLineContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
	align-items: center;
`;

const DeleteButton = styled(BaseButton)`
  text-decoration: none;
  //text-transform: uppercase;
  padding: 0.5em 1em;
  color: ${theme.subHeader.buttonTextColor};
  border-radius: 1.5em;
	margin-right: 1em;
  box-shadow: none;
  background: ${theme.buttons.defaultColor};
  border: none;
  
  transition: all .2s;

  &:active {
    outline: none;
    opacity: 0.6;
    scale: 0.9;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    opacity: 0.7;
    scale: 0.95;
  }
`;

const MessageTitle = styled.h2`
  font-size: 1.4em;
  margin: 0 0 15px;
  padding: 0;
`;

const MessageText = styled.p`
  font-size: 1em;
  margin: 8px 0;
  padding: 0;
  font-weight: 600;
  
  white-space: pre-wrap;
`;

const MessageLink = styled.p`
  font-size: 1em;
  margin: 8px 0;
  padding: 0;
  font-weight: 600;
  color: #3b99fc;
	transition: all .2s;
	&:hover {
		opacity: 0.8;
		cursor: pointer;
	}
	&:active {
		opacity: 0.6;
	}
`;

const MessageSubTitle = styled.p`
  font-size: 1em;
  margin: 8px 0;
  padding: 0;
  opacity: 0.7;
  font-weight: 600;
`;

const DateText = styled.div`
  font-size: 1.2em;
  color: #dcdcdc;
  display: flex;
  justify-content: flex-start;
`;
const DateTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 2em;
`;

const MessageInput = styled.textarea`
	padding: 8px;
	border-radius: 8px;
	outline: none;
	appearance: none;
	border: none;
	width: 100%;
`

const MessageCard = (props: TProps) => {
	const [selected, setSelected] = useState(null);
	const [notification, setNotification] = useState(null);
	const {message, accounts, onEdit, isArchive, isDraft, archiveMessage, publishMessageDraft, restoreArchivedMessage, accountId} = props;
	const account = accounts ? accounts.find(acc => acc.accountId === accountId) : null;
	const hasVendors = account?.messageVendors ?? null;
	const options = hasVendors ? accounts.filter(acc => account.messageVendors.some(o => o.accountId === acc.accountId)).map(acc => ({
		value: acc.accountId,
		label: acc.accountName,
		logoUrl: acc.logoUrl,
	})) : [];
	
	return (
		<MessagesCard>
			<Flex wrap>
				<Box w={[1]}>
					<div style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}>
						{message.imageUrl ?
							<img
								alt={"preview"}
								src={message.imageUrl}
								style={{
									width: 300,
									height: "100%",
									objectFit: "cover",
								}}
							/> :
							null
						}
						
						<MessageInfoContainer>
							
							{(notification !== null) ? <div>
								<MessageSubTitle style={{opacity: 0.5}}>Notification</MessageSubTitle>
								<MessageInput
									value={notification}
									onChange={(e) => setNotification(e.target.value)}
									type="text"
								/>
							</div> : null}

							<MessageSubTitle>Headline</MessageSubTitle>
							<MessageTitle>{message.headline} </MessageTitle>

							<MessageSubTitle>Body</MessageSubTitle>
							<MessageText>
								{message.description}
							</MessageText>

							{message?.url ? <div>
								<MessageSubTitle>Link</MessageSubTitle>
								<MessageLink onClick={() => window.open(message.url, "_blank")}>
									{message.url}
								</MessageLink>
							</div> : null}

							<DateTextContainer>
								{message.date &&
									<DateText>Created: {message.date.hours}:{message.date.minutes}, {message.date.day}.{message.date.month}.{message.date.year}</DateText>
								}
							</DateTextContainer>
						</MessageInfoContainer>
					</div>
					
					<LastLineContainer>
						<DeleteButtonContainer>
							{(isDraft && hasVendors) ? <div
								style={{
									flexShrink: 0,
									gap: 20,
									width: "30%",
									marginRight: 20,
									color: "black",
								}}
							>
								<MultiSelect
									options={options}
									value={selected ?? options}
									onChange={setSelected}
									labelledBy="Select accounts"
									overrideStrings={{
										selectSomeItems: "Select accounts to publish to...",
										allItemsAreSelected: "All accounts are selected.",
									}}
									ItemRenderer={({checked, option, onClick, disabled}) =>
										<div style={{alignItems: "center"}} className={`item-renderer ${disabled ? "disabled" : ""}`}>
											<input
												type="checkbox"
												onChange={onClick}
												checked={checked}
												tabIndex={-1}
												disabled={disabled}
											/>
											{
												option.logoUrl ?
													<img
														src={option.logoUrl}
														alt={"account-logo"}
														style={{
															width: 50,
															height: 40,
															marginRight: 10,
															marginLeft: 10,
															objectFit: "contain",
														}}
													/>
													:
													null
											}
											<span style={{color: "black"}}>{option.label}</span>
										</div>
									}
									hasSelectAll
								/>
							</div> : null}

							{isDraft ? <DeleteButton
								onClick={() => {
									if (notification != null && notification?.length > 0) {
										publishAction({accountId, notification, message, publishMessageDraft, accounts: (selected ?? options).map(s => s.value)})
									} else {
										publishAction({accountId, message, publishMessageDraft, accounts: (selected ?? options).map(s => s.value)})
									}
								}}
							>
								Publish
							</DeleteButton> : null}

							{(isDraft && notification === null) ? <DeleteButton
								onClick={() => {
									if (notification === null) {
										setNotification("");
									}
								}}
							>
								Add notification
							</DeleteButton> : null}

							{!isArchive ? <DeleteButton
								onClick={onEdit}
							>
								Edit
							</DeleteButton> : null}
							
							{isArchive ? <DeleteButton
								onClick={() => draftAction({accountId, message, restoreArchivedMessage})}
							>
								Restore to draft
							</DeleteButton> : null}
							
							{!isArchive ? <DeleteButton
								onClick={() => deleteAction({accountId, message, archiveMessage})}
							>
								Archive
							</DeleteButton> : null}
						</DeleteButtonContainer>
					
					</LastLineContainer>
				</Box>
			</Flex>
		</MessagesCard>
	);
};

export default MessageCard;
