/**
* @flow
*/
import React, { Component } from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';

import Wrapper from '../../components/Typografy/Wrapper';
import { mx } from '../../utils';
import Section from './components/Section';
import { Link } from 'react-router-dom';


const VideoTopWrapper = styled(Wrapper)`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    `;



const Container = styled.div`
      background-color: #141414;
    `;

const TopWrapper = styled(Flex)`
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    flex: 1;
    margin-top: 6em;
    
    ${mx('lg')} {
      flex-direction: row-reverse;
      }
    `;

const VideoWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
      ${mx('lg')} {
          width: 60vw;
      }
    `;

const ScrollIcon = styled.div`
    background-image: url(${require('../../assets/images/scroll.png').default});
    margin-top: 0.5em;
    width: 24px;
    height: 48px;
    cursor: pointer;
    `;

const ScrollText = styled.span`
    font: 0.5em;
    //text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    `;

const ScrollWrapper = styled.div`
    display: none;
    margin-top: 1em;
    ${mx('lg')} {
      z-index: 2;
      text-decoration: none;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      }
    `;

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: space-evenly;
    align-items: center;

    ${mx('xl')} {
        flex-direction: row;
    }
`

const PhoneImage = styled.img`
    height: auto;
    max-height: 72vh;
    max-width: 95vw;
`

const TextWrapper = styled.div`
    max-width: 50ch;
`

const Title = styled.h1`
    font-size: 3.5em;
    color: white;
    margin: 0;
`

const Description = styled.p`
    font-size: 1.4em;
    color: white;
`

const ButtonWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    gap: 20px;
`

const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #f41a4d;
    outline: none;
    border: none;
    color: white;
    transition: all .2s;
    text-decoration: none;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    &:active {
        opacity: 0.6;
    }

    font-size: 18px;

    ${mx('lg')} {
        font-size: 22px;
    }
`

const ButtonLight = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    outline: none;
    border: none;
    transition: all .2s;
    min-width: 10ch;
    text-decoration: none;
    color: black;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    &:active {
        opacity: 0.6;
    }

    font-size: 18px;

    ${mx('lg')} {
        font-size: 22px;
    }
`

class SelfServiceSection extends Component {
    render() {
        return (
            <Section>
                <Container>
                    <VideoTopWrapper>
                        <TopWrapper wrap>
                            <MainWrapper>
                                <TextWrapper>
                                    <Title>
                                        Bli en bedre arbeidsgiver!
                                    </Title>
                                    <Description>
                                        <strong>Self Service</strong> gir dere en komplett pakke med fordeler og full kontroll til å legge til og administrere deres egne goder.
                                    </Description>
                                    <Description>
                                        Med enkel pålogging kan deres ansatte kjapt komme i gang med å bruke fordelene. Tilpass deres egen app i dag!
                                    </Description>

                                    <ButtonWrapper>
                                        <Button target="_blank" href="https://admin.coveredpro.com/registration">
                                            Kom i gang nå
                                        </Button>
                                        <ButtonLight to="/login">
                                            Logg inn
                                        </ButtonLight>
                                    </ButtonWrapper>
                                </TextWrapper>
                                <PhoneImage
                                    src={"https://i.imgur.com/Tl1ldYk.png"}
                                />
                            </MainWrapper>
                        </TopWrapper>
                        <ScrollWrapper onClick={() => this.props.onMenuItemClick('promotional')}>
                            <ScrollText>Les mer</ScrollText>
                            <ScrollIcon />
                        </ScrollWrapper>
                    </VideoTopWrapper>
                </Container>
            </Section>
        );
    }
}

export default SelfServiceSection;
